select {
 color: #fff !important;
 background-color: #000;
 padding: 8px !important;
}
#html5qr-code-full-region {
  color: #ffffff !important;
  width: 100%;
  border-style: none !important;
}
#html5qr-code-full-region img {
  background: #ffffff !important;
  margin: auto !important;
  display: none;
}
/* #html5qr-code-full-region__dashboard_section_csr {
  display: none !important;
} */
/* #html5qr-code-full-region__dashboard_section_csr span {
  display: none !important;
} */
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  position: relative;
  background: #000;
  border: 0;
  padding: 14px 42px;
  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.2em;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

button:after {
  content: '';
  position: absolute;
  top: 0;
  left: -200%;
  width: 200%;
  height: 100%;
  transform: skewX(-20deg);
  background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.3), transparent);
}

button:hover:after {
  animation: shine 1.6s ease;
}

button:active {
  transform: translateY(1px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.cntr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@-moz-keyframes shine {
  100% {
    left: 200%;
  }
}

@-webkit-keyframes shine {
  100% {
    left: 200%;
  }
}

@-o-keyframes shine {
  100% {
    left: 200%;
  }
}

@keyframes shine {
  100% {
    left: 200%;
  }
} */